import Navbar from "../components/Navbar";
import ContactCollage from "../assets/Contactpage2.jpg";

import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Box
        border="1px"
        maxW="md"
        borderColor={"#52683B"}
        m={"8%"}
        p={8}
        py={"5%"}
        borderRadius={"lg"}
        boxShadow={"lg"}
        mx="auto" // This will center the box horizontally
      >
        {/* Contact Information Section */}
        {/* <GridItem> */}
        <VStack align="flex-start" spacing={4} p={6}>
          <Heading size="xl" color="teal.600">
            Contact Us
          </Heading>
          <Text fontSize="md">
            <strong>Email:</strong> orders@sincerodistribution.com
          </Text>
          <Text fontSize="md">
            <strong>Phone:</strong> +1 (929) 698-4455
          </Text>
          <Text fontSize="md">
            <strong>Location:</strong> NYC, USA
          </Text>
        </VStack>
        {/* </GridItem> */}
        {/* <GridItem>
          <Image
            src={ContactCollage} // Replace with your image URL
            alt="Contact Image"
          />
        </GridItem> */}
        {/* </Grid> */}
      </Box>
    </>
  );
};

export default ContactPage;
